import { API, BASE_DOMAIN } from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react"
import { Group, Rect, Text } from "react-konva"
import useImage from "use-image"
import { getMapCoord } from "../Polygon"
import { animated, Spring } from '@react-spring/konva';
import { easePoly } from 'd3-ease';
import { useMapStore } from '@/stores/mapStore';
import Konva from "konva"

export const defaultMapPointImage = {
    owner_id: "15",
    image_id: "2d95a0df350c4c2dae7ab2c0c03bf337",
    extension: "png"
}

export const getImageLink = (image) => {
    if (!image || !image.owner_id) return ''
    return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}`
}

const useMapAvatar = (bookingData, node) => {
    const imgSrc = bookingData ? API.user.getAvatar({ userId: Number(bookingData.user_id) }) : getImageLink(node?.icon) || getImageLink(defaultMapPointImage)
    const [pointImg] = useImage(imgSrc)

    return { image: pointImg }
}

export const isBookable = (data) => {
    if (!data) return false
    const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
    if (!plugin) return false
    return plugin.bookable
}

const defaultNode = {
    background: "#ffffff",
    border: "#000000",
    radius: 50,
    uid: "ae4c38a5a5994d8082029b51370111a3",
    name: "Сервер",
}

const Point = ({ point, options, nodes, bookings }) => {

    const textRef = useRef<any>(null)
    const { id, x, y, name, type_uid, plugin_data } = point
    const { labelSize, fontSize, color, borderWidth, wrapText } = options

    const bookingData = useMemo(() =>  bookings?.find(book => book.point_id == id), [bookings, id])
    const avaliable = useMemo(() => isBookable(plugin_data), [plugin_data])
    
    const node = nodes[type_uid] || defaultNode
    const text = bookingData?.display || name
    const displayText = wrapText ? text.split(' ').join('\n') : text

    const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })
    const { image } = useMapAvatar(bookingData, node)

    const setSeat = useGlobalStore(state => state.setSeat)
    const setSeatEmployee = useGlobalStore(state => state.setSeatEmployee)
    const [width, height] = useMapStore(state => state.size)

    const onSelect = useCallback(
        () => {
            setSeat(Number(point.id))
            if (bookingData) {
                setSeatEmployee(Number(bookingData.user_id))
            } else {
                setSeatEmployee(null)
            }
        },
        [setSeat, setSeatEmployee]
    )

    useEffect(() => {
       if (width > 0 && height > 0) {
        const pointX = (-textRef.current?.textWidth / 2) + (width * labelSize) / 2 || (width * labelSize) / 2
        setCoord({
            x: getMapCoord(width, x),
            y: getMapCoord(height, y),
            pX: Number(pointX)
        })
       }
    }, [textRef.current, image, width, height])

    if (!image) return null

    return (
        <Group
            x={coord.x}
            y={coord.y}
            offsetX={width * labelSize / 2}
            offsetY={width * labelSize / 2}
            onClick={onSelect}
            onTap={onSelect}
        >
            <Group
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                opacity={avaliable ? 1 : 0.3}
                id={"point" + point.id}
            >
                <Rect
                    width={width * labelSize}
                    height={width * labelSize}
                    stroke={node?.border}
                    strokeWidth={fontSize * borderWidth}
                    fill={node?.background}
                    cornerRadius={node?.radius}
                    shadowForStrokeEnabled={false}
                    perfectDrawEnabled={false}
                    listening={avaliable}
                />
                <Rect
                    width={width * labelSize}
                    height={width * labelSize}
                    cornerRadius={node?.radius}
                    fillPatternImage={image}
                    fillPatternScaleX={(width * labelSize) / image?.width}
                    fillPatternScaleY={(width * labelSize) / image?.width}
                    shadowForStrokeEnabled={false}
                    perfectDrawEnabled={false}
                />
            </Group>
            <Text
                ref={textRef}
                text={displayText}
                align="center"
                fontSize={fontSize}
                fill={color}
                y={width * 1.1 * labelSize}
                x={coord.pX}
                listening={avaliable}
                perfectDrawEnabled={false}
            />
        </Group>
    )
}


export const scaleRelativeToPoint = (stage, point, scale, onRescale) => {
    const scaleBy = 1.25;
    const oldScale = stage.scaleX()

    const mousePointTo = {
        x: point.x / oldScale - stage.x() / oldScale,
        y: point.y / oldScale - stage.y() / oldScale
    };

    const newScale = scale;
    const x = (point.x / newScale - mousePointTo.x) * newScale
    const y = (point.y / newScale - mousePointTo.y) * newScale


    onRescale({
        scale: newScale,
        point: {
            x,
            y
        }
    });
};


export const AnimatedPoint: React.FC<any> = React.memo((props) => {
    const { id } = props

    const seat = useGlobalStore(state => state.seat)
    const currentSeat = useMemo(() => seat == id, [seat, id])

    return (
        <Spring
            from={{ opacity: 1 }}
            to={currentSeat ? [
                { opacity: 0.2 },
                { opacity: 1 },
                { opacity: 0.2 },
                { opacity: 1 },
            ] : { opacity: 1 }}
            config={{
                easing: easePoly.exponent(2)
            }}
        >
            {springProps => (
                // @ts-ignore
                <animated.Group {...springProps}>
                   <Point {...props} />
                </animated.Group>
            )}
        </Spring>
    )
})

AnimatedPoint.whyDidYouRender = true

export default React.memo(Point)

function onMouseEnterHandler(e) {
    const container = e.target.getStage()?.container()

    if (container) {
        container.style.cursor = "pointer"
    }

}

function onMouseLeaveHandler(e) {
    const container = e.target.getStage()?.container()

    if (container) {
        container.style.cursor = "default"
    }

}