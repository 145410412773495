import React from 'react'
import styled from 'styled-components';
import MapStatus from './MapStatus';
import MapContainer from './MapContainer';

const Map: React.FC = () => {
    return (
        <MapWrapper>
            <MapContainer />
            <MapStatus />
        </MapWrapper>
    )
}

Map.whyDidYouRender = true

export default Map

const MapWrapper = styled.div`
    position: relative;
`