import React from 'react'
import Sidebar from '../Sidebar';
import TreeView from './TreeView';
import { useTree } from '@/api/hooks/useTree';

const Layers = () => {
    // fetch data hooks
    const { layers } = useTree()

    return (
        <Sidebar>
            <Sidebar.Header title="Уровни" onAdd={() => console.log('add layer')} />

            {layers?.tree ? (
                <TreeView data={layers.tree} />
            ) : (
                <div style={{ padding: '1rem 0' }}>Загрузка...</div>
            )}
        </Sidebar>
    )
}

export default Layers