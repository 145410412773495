import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import ProjectContainer from './ProjectContainer';
import LoginContainer from './LoginContainer';
import NotFound from '@/components/NotFound';
import ProtectedRoutes from "@/components/ProtectedRoutes";
import TestPage from "@/pages/test/TestPage";

const AppContainer = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginContainer />} />
                <Route element={<ProtectedRoutes />}>
                    <Route path="/project/:workspaceId/:projectId" element={<ProjectContainer />} />
                </Route>
                <Route path="/test" element={<TestPage />} />
                <Route index element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default AppContainer