import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik';
import RemoveIcon from '@/components/icons/RemoveIcon';
import { DatePickerField } from '@/ui/components/Field/DatePickerField';
import PlusIcon from '@/components/icons/PlusIcon';
import { getHours, getMinutes, setHours, setMinutes, isAfter, isToday, isSameDay, addMinutes, isEqual } from 'date-fns';
import useResponsive from '@/hooks/useResponsive';

const BookingInterval = ({ name, interval, index, count, helpers }) => {
    const { setFieldValue } = useFormikContext<any>()
    const start = interval.start ? addMinutes(interval.start, 30) : null
    const { isDesktop } = useResponsive()

    const isItSame = interval.end ? isSameDay(interval.start, interval.end) : true
    const minHours = start ? getHours(start) : 0
    const minMinutes = start ? getMinutes(start) : 0
    const minTime = isItSame ? setHours(setMinutes(new Date(), minMinutes), minHours) : setHours(setMinutes(new Date(), 0), 0)

    return (
        <Grid key={'dates ' + index} container>
            <Grid item xs={5} md={4}>
                <FormLabel>Дата и время начала</FormLabel>
                <Field
                    name={`${name}[${index}].start`}
                    placeholder="Время начала"
                    component={DatePickerField}
                    $fullWidth
                    onChange={val => {
                        const isIntervalAfter = interval.end && val ? isAfter(val, interval.end) : false
                        const equality = interval.end && val ? isEqual(val, interval.end) : false

                        if (isIntervalAfter || equality) {
                            setFieldValue(`${name}[${index}].end`, addMinutes(val, 30));
                        }

                        setFieldValue(`${name}[${index}].start`, val);
                    }}
                />
            </Grid>
            <Grid item xs={5} md={4}>
                <FormLabel>Дата и время окончания</FormLabel>
                <Field
                    $fullWidth
                    name={`${name}[${index}].end`}
                    type="text"
                    placeholder="Время окончания"
                    // selected={start}
                    openToDate={start}
                    minDate={start || null}
                    minTime={minTime}
                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    component={DatePickerField}
                    onChange={val => {
                        const isIntervalAfter = interval.start && val ? isAfter(val, interval.start) : false
                        const equality = interval.start && val ? isEqual(val, interval.start) : false

                        if (equality || !isIntervalAfter) {
                            setFieldValue(`${name}[${index}].end`, addMinutes(interval.start, 30));
                        } else {
                            setFieldValue(`${name}[${index}].end`, val);

                        }
                    }}
                />
            </Grid>
            <Buttons>
                {count > 0 && (
                    <BtnWrapper type="button" onClick={() => helpers.remove(index)}>
                        <RemoveIcon color="#fff" />
                    </BtnWrapper>
                )}
                {isDesktop && (
                    <BtnWrapper type="button" onClick={() => helpers.insert(index, { start: '', end: '' })}>
                        <PlusIcon color="#fff" />
                    </BtnWrapper>
                )}
            </Buttons>
        </Grid>
    )
}

export const NoBookingIntervals = ({ helpers }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <AddButton type="button" onClick={() => helpers.push({ start: '', end: '' })}>
                    <BtnWrapper as="div">
                        <PlusIcon color="#fff" />
                    </BtnWrapper>
                    <BtnText>Добавить интервал</BtnText>
                </AddButton>
            </Grid>
        </Grid>
    )
}

export default BookingInterval

const Buttons = styled.div`
    display: flex;
    margin-top: 34px;
    justify-content: space-around;
    /* align-items: center; */
`

const BtnWrapper = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: #079DAC;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        opacity: 0.7;
    }
`

const BtnText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    margin-left: 12px;
    cursor: pointer;
`

const AddButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
`