import AppLayout from '@/components/layout/AppLayout'
import Datatable from '@/components/ui/datatable/Datatable'
import React from 'react'

const columns = [
    {
        id: 'id',
        label: 'id',
    },
    {
        id: 'name',
        label: 'ФИО',
    },
    {
        id: 'department',
        label: 'Отдел',
    },
    {
        id: 'phone',
        label: 'Телефон',
    },
    {
        id: 'email',
        label: 'Почта',
    },
    {
        id: 'controls',
        label: '',
    },
]

function createData(id, name, department, phone, email) {
    return {
        id,
        name,
        department,
        phone,
        email,
    }
}

const data = [
    createData(1, 'admin', 'Администрация', '+7 900 000 00 01', 'admin@officescheme.ru'),
    createData(4, 'alexey', 'Яндекс', '+7 900 000 00 02', 'alexey@officescheme.ru'),
    createData(7, 'boris', 'Яндекс', '+7 900 000 00 03', 'boris@officescheme.ru'),
    createData(8, 'nikolay', 'Лавка', '+7 900 000 00 04', 'nikolay@officescheme.ru'),
    createData(11, 'inna', 'Такси', '+7 900 000 00 05', 'inna@officescheme.ru'),
]

const TestPage = () => {
    return (
        <AppLayout>
            <Datatable columns={columns} data={data} />
        </AppLayout>
    )
}

export default TestPage