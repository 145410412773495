import React, { useEffect, useMemo, useState } from 'react'
import MapStage from '@/components/shared/map/MapStage'
import MapCoverLayer from './MapCoverLayer'
import PolygonsLayer from './PolygonsLayer'
import PointsLayer from './PointsLayer'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { useMapStore } from '@/stores/mapStore'
import Konva from 'konva'
import { hexToRgba } from '@/lib/hexToRgb';

const MapContainer = () => {
    const activeLayer = useGlobalStore(state => state.activeLayer)
    const selection = useGlobalStore(state => state.selection)
    const nodes = useProjectStore(state => state.nodes)
    const stage = useMapStore(state => state.stage)
    const setPolygon = useMapStore(state => state.setPolygon)
    const node = nodes.find(n => n.id == activeLayer)
    const hasOwnView = node?.ownView

    const mapLayer = hasOwnView ? node.id : node?.parent || activeLayer

    const { layerView } = useLayerView(Number(mapLayer))
    const { metadata } = useMetadata()
    const { data } = useBookingsForLayer(Number(mapLayer), selection.startDate, selection.endDate)

    // useEffect(() => {
    //     if (hasOwnView || !node) return

    //     const polygon = stage.findOne('#layer_' + node.id)

    //     const anim = new Konva.Animation((frame) => {
    //         polygon.to({
    //             fill: hexToRgba('#000', frame.time / 1000)
    //         })
    //       }, polygon)
    //       anim.start()
    // }, [node])

    return (
        <MapStage>
            <MapCoverLayer
                view={layerView?.view}
            />
            <PolygonsLayer
                polygons={layerView?.polygons}
            />
            <PointsLayer
                nodes={metadata?.rawNodes}
                points={layerView?.points}
                options={layerView?.options}
                bookings={data?.bookings}
            />
        </MapStage>
    )
}

MapContainer.whyDidYouRender = true

export default MapContainer