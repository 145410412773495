import React, { useState } from 'react'
import Sidebar from '../Sidebar';
import styled from 'styled-components'
import ReportsItems from './ReportsItem';
import ReportModal from './ReportModal';

// const mockData = ["Парковки на завтра", "Брони на вчера", "Количество занятых мест"]
const mockData = [{
    name: "Нагрузка на рабочее место",
    key: "place"
}, {
    name: "Отчет по площадям",
    key: "square"
}]

const Report = () => {

    return (
        <Sidebar>
            <Sidebar.Header title="Отчеты" onAdd={() => alert('Добавление отчета')} />
            <Sidebar.Content>
                {mockData.map(item => <ReportsItems key={'report ' + item} item={item} />)}
            </Sidebar.Content>
        </Sidebar>
    )
}

export default Report