import React, { useState } from 'react'
import { hexToRgba } from '@/lib/hexToRgb';
import { Line } from 'react-konva';
import { PolygonData } from '@/api/layer';
import { useGlobalStore } from '@/stores/globalStore';

interface PolygonElementProps {
    id: number
    width: number
    height: number
    polygon: PolygonData
}

export const getMapCoord = (value:number, percent: number) => value * (percent / 100)

const Polygon: React.FC<PolygonElementProps> = ({ id, width, height, polygon }) => {
    const [fill, setFill] = useState('transparent')
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)

    const data = polygon.polygon.reduce((acc, val) => [...acc, getMapCoord(width, val[0]), getMapCoord(height, val[1])], [])

    const onMouseEnterHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "pointer"
        }

        setFill(hexToRgba(polygon.fill, polygon.hover / 100))
    }

    const onMouseLeaveHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "default"
        }
        
        setFill('transparent')
    }

    const handleClick = (e) => {
        e.evt.preventDefault()
        setActiveLayer(id)
    }

    return (
        <Line
            stroke={polygon.stroke}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            fill={fill}
            points={data}
            closed
            onClick={handleClick}
            onTap={handleClick}
            perfectDrawEnabled={false}
            shadowForStrokeEnabled={false}
        />
    )
}

export default Polygon