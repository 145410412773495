import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { ProjectService } from '../services/project.service';
import { useProject } from '@/hooks/useProject';

export const useMetadata = () => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['metadata', workspaceId, projectId],
        () => ProjectService.getMetadata({ workspaceId, projectId }),
        {
            enabled: !!workspaceId && !!projectId,
            // keepPreviousData: true,
            select: ({ data }) => {
                const nodes = data?.metablock.nodes ? Object.values(data?.metablock?.nodes) : null
                return {
                    data,
                    status: data.status,
                    rawNodes: data?.metablock.nodes,
                    nodes,
                    layers: data.metablock.layers
                }
            },
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить метаданные проекта' }, { variant: 'error' })
            }
        }
    )

    return { metadata: data, data, isLoading, refetch }
}