import Search, { SearchButton } from '@/components/layout/appbar/Search';
import Close from '@/components/Close';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components'
import { NodeElement } from '@/api/project';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useProjectStore } from '@/stores/projectStore';
import { useNodes } from '@/api/hooks/useNodes';
import { SelectField, SelectInput } from '@/ui/components/Field/Select';
import { Input } from '@/ui/components/Field/Input';
import { debounce } from 'lodash';
import FormLoader from '@/components/ui/form/FormLoader';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { ControlsWrapper, FormControls } from '@/components/ui/form/FormControls';
import useResponsive from '@/hooks/useResponsive';
import MobilePlaceItem from './MobilePlaceItem';
import { useFormikContext } from 'formik';

type ReportModalProps = {
    isOpen: boolean
    onClose: () => void
    setFieldValue: any
}

const SelectSeatModal: React.FC<ReportModalProps> = ({ isOpen, onClose }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [place, setPlace] = useState<any>(null)
    const [parent, setParent] = useState<string>('')
    const [name, setName] = useState<string>('')

    const { values, setFieldValue } = useFormikContext<any>()
    const { isMobile } = useResponsive()

    const nodes = useProjectStore(state => state.nodes)
    const { data, isLoading } = useNodes({ page: currentPage - 1, perPage: 20, name, parent, bookable: 1 })

    const handleSelection = async () => {
        if (place) {
            setFieldValue('seat', {
                id: place.id,
                name: [place.place, place.spot].join('. ')
            })
        }
        onClose()
    }

    useEffect(() => {
        if (isOpen) {
            setPlace({
                id: values.seat.id || '',
                name: values.seat.name || ''
            })
            setParent('')
            setCurrentPage(1)
            setName('')
        }
    }, [isOpen])

    const handleNameChange = (e) => setName(e?.target?.value)
    const handleParentChange = (e) => setParent(e?.target?.value)

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={900}
        >
            <Header>
                <Title>Выбор места</Title>
                <Close color="#000" onClick={onClose} />
            </Header>
            <div>
                <Grid>
                    <Filters>
                        <FiltersItem>
                            <SelectInput
                                $fullWidth
                                onChange={handleParentChange}
                                value={parent}
                            >
                                <option value="">Все уровни</option>
                                {nodes.map(node => <option key={node.id} value={node.id}>{node.name}</option>)}
                            </SelectInput>
                        </FiltersItem>
                        <FiltersItem>
                            <Input
                                $fullWidth
                                placeholder='Поиск по названию'
                                onChange={debouncedNameResponse}
                                // value={name}
                            />
                        </FiltersItem>
                        <FiltersSearch>
                            <SearchButton />
                        </FiltersSearch>
                    </Filters>

                    {!isMobile && (
                        <Grid.RowHeader $cols="20px 1fr 1fr 1fr">
                            <Grid.Item />
                            <Grid.Item>Расположение</Grid.Item>
                            <Grid.Item>Место</Grid.Item>
                            <Grid.Item>Тип</Grid.Item>
                        </Grid.RowHeader>
                    )}

                    {data && data.items && data?.items.length ? (
                        <>
                            {!isMobile && data.items.map(place => (
                                <PlaceItem
                                    key={place.id}
                                    place={place}
                                    handleSelection={setPlace}
                                />
                            ))}
                            {isMobile && data.items.map(placeData => (
                                <MobilePlaceItem
                                    key={placeData.id}
                                    place={placeData}
                                    selected={place}
                                    handleSelection={setPlace}
                                />
                            ))}
                            
                            <PaginationWrapper>
                                <Pagination inverse currentPage={currentPage} total={data.total} itemsPerPage={20} handlePageChange={setCurrentPage} />
                            </PaginationWrapper>
                        </>
                    ) : <NotFound>Ничего не найдено</NotFound>}

                    <FormLoader isLoading={isLoading} />

                    <ControlsWrapper>
                        <FormControls>
                            <PrimaryButton $fullWidth type="submit" onClick={handleSelection}>Выбрать</PrimaryButton>
                            <DefaultButton $fullWidth onClick={onClose} type="button">Отменить</DefaultButton>
                        </FormControls>
                    </ControlsWrapper>
                </Grid>
            </div>
        </Modal>
    )
}

export default SelectSeatModal

const NotFound = styled.div`
    width: 100%;
    text-align: center;
    padding: 12px 0;
`

const Filters = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 60px;
    column-gap: 14px;
    padding: 6px 0 14px 0;
`
const FiltersSearch = styled.div`
`
const FiltersItem = styled.div`
`

const PlaceItem: React.FC<{ place: NodeElement, handleSelection: React.Dispatch<any> }> = ({ place, handleSelection }) => {
    const { nodes } = useProjectStore()
    const placename = nodes.find(tree => tree.id == Number(place.parent))

    return (
        <Grid.Row $cols="20px 1fr 1fr 1fr" as="label" $label onClick={() => {
            handleSelection({
                place: placename?.name || 'Не указано',
                spot: place.name,
                id: place.id
            })
        }}>
            <Grid.Item>
                <input type="radio" name="employee" value="1" />
            </Grid.Item>
            <Grid.Item>
                {placename ? placename.name : 'Не указано'}
            </Grid.Item>
            <Grid.Item>{place.name}</Grid.Item>
            <Grid.Item>{place.type_name}</Grid.Item>
        </Grid.Row>
    )
}


const PaginationWrapper = styled.div`
    padding: 2rem 0 0;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`