import React, { useState, useMemo, useEffect } from 'react'
import { Input } from '@/ui/components/Field/Input';
import { Grid } from '@/components/Grid';
import ElementsListItem from './ElementsListItem';
import Toolbar from '../Toolbar/Toolbar';
import { SelectInput } from '@/ui/components/Field/Select';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useNodes } from '@/api/hooks/useNodes';
import { debounce } from 'lodash';
import { useProjectStore } from '@/stores/projectStore';
import { useMetadata } from '@/api/hooks/useMetadata';
import Sidebar from '../layout/Sidebar/Sidebar';
import useResponsive from '@/hooks/useResponsive';
import useSorting from '@/hooks/useSorting';
import { TableCell } from '@/components/Employee/Employees';

const Elements = () => {
    const nodes = useProjectStore(state => state.nodes)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [parent, setParent] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [name, setName] = useState<string>('')
    const { sort, direction, handleSort } = useSorting()

    const { data, isLoading } = useNodes({ page: currentPage - 1, name, parent, type, sort, direction })
    const { metadata } = useMetadata()
    
    const handleNameChange = (e) => setName(e?.target?.value)
    const handleParentChange = (e) => setParent(e?.target?.value)
    const handleTypeChange = (e) => setType(e?.target?.value)
    const { isDesktop } = useResponsive()

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    return (
        <Sidebar minWidth={900}>
            <Sidebar.Header title="Объекты" onAdd={() => alert('Добавление Объекты')} />

            <Toolbar>
                <Toolbar.Item xs={12} md={4}>
                    <Toolbar.Label>Поиск по названию</Toolbar.Label>
                    <Input $fullWidth placeholder="Поиск" onChange={debouncedNameResponse} />
                </Toolbar.Item>
                <Toolbar.Item xs={6} md={4}>
                    <Toolbar.Label>Расположение</Toolbar.Label>
                    <SelectInput $fullWidth placeholder="Выберите расположение" value={parent} onChange={handleParentChange}>
                        <option value="">Все уровни</option>
                        {nodes.map(node => <option key={node.id} value={node.id}>{node.name}</option>)}
                    </SelectInput>
                </Toolbar.Item>
                <Toolbar.Item xs={6} md={4}>
                    <Toolbar.Label>Тип объектов</Toolbar.Label>
                    <SelectInput $fullWidth placeholder="Выберите тип места"  value={type} onChange={handleTypeChange}>
                        <option value="">Все</option>
                        {metadata?.nodes?.map(node => <option key={node.uid} value={node.uid}>{node.name}</option>)}
                    </SelectInput>
                </Toolbar.Item>
            </Toolbar>

            <Grid>
                {isDesktop && (
                    <Grid.RowHeader $cols="40px 2fr 1fr 1fr 30px 30px">
                        <TableCell
                            onClick={handleSort.bind(null, 'id')}
                            $active={sort === 'id'}
                            $direction={direction}
                        >
                            id
                        </TableCell>
                        <TableCell
                            onClick={handleSort.bind(null, 'name')}
                            $active={sort === 'name'}
                            $direction={direction}
                        >
                            Наименование
                        </TableCell>
                        <TableCell
                            onClick={handleSort.bind(null, 'parent')}
                            $active={sort === 'parent'}
                            $direction={direction}
                        >
                            Расположение
                        </TableCell>
                        <TableCell
                            onClick={handleSort.bind(null, 'type_name')}
                            $active={sort === 'type_name'}
                            $direction={direction}
                        >
                            Тип
                        </TableCell>
                        <Grid.Item />
                        <Grid.Item />
                    </Grid.RowHeader>
                )}

                {data?.items.map(spot => <ElementsListItem key={spot.id} item={spot} />)}

            </Grid>

            <Pagination currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} />

        </Sidebar>
    )
}

export default Elements