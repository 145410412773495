import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState } from 'react'
import { Circle, Group, Image, Layer, Rect, Stage, Text, TextPath } from 'react-konva'
import useImage from 'use-image'
import { animated, Spring } from '@react-spring/konva'
import { PointType, PolygonType } from '@/api/layer'
import Polygon, { getMapCoord } from './MapElements/Polygon'
import { ProjectMetadataResponse } from '@/api/project'
import { useQueryClient } from 'react-query';
import { PointOptionsType } from '@/containers/MapContainer'
import { useGlobalStore } from '@/stores/globalStore'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer';
import { API, BASE_DOMAIN } from '@/api'
import Point, { AnimatedPoint } from './MapElements/Point'
import { useMetadata } from '@/api/hooks/useMetadata'

interface MapProps {
    image: string
    polygons: PolygonType[]
    points: PointType[]
    pointOptions: PointOptionsType
}

const scaleBy = 1.25

const Map: React.FC<MapProps> = ({ image, polygons, points, pointOptions }) => {
    const stageRef = useRef<any>(null)
    const [src, status] = useImage(image)
    const { metadata } = useMetadata()

    const { width, height } = useWindowSize()

    // data selectors on map
    const scale = useMapStore(state => state.scale)
    const point = useMapStore(state => state.point)
    const zoomSeat = useMapStore(state => state.zoomSeat)
    const onRescale = useMapStore(state => state.onRescale)
    const setStage = useMapStore(state => state.setStage)
    const setInitialized = useMapStore(state => state.setInitialized)
    const activeLayer = useGlobalStore(state => state.activeLayer)
    const selection = useGlobalStore(state => state.selection)

    const { data } = useBookingsForLayer(activeLayer, selection.startDate, selection.endDate)

    const handleScale = (e) => {
        e.evt.preventDefault();

        const stage = stageRef.current
        const mousePointTo = {
            x: stage.getPointerPosition().x / scale - stage.x() / scale,
            y: stage.getPointerPosition().y / scale - stage.y() / scale
        }

        const newScale = e.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy

        // update state
        onRescale({
            scale: newScale,
            point: {
                x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
                y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
            }
        })
    }

    useEffect(() => {
        if (!stageRef.current) return

        const tween: any = {
            scaleX: scale,
            scaleY: scale,
            duration: 0,
        }

        if (point) {
            tween.x = point.x
            tween.y = point.y
        }

        stageRef.current.to(tween)

    }, [scale, point])

    // useEffect(() => {
    //     resize()
    // }, [width, height])

    useEffect(() => {
        if (status === 'loaded' && src) {
            setStage(stageRef.current)
            resize()
        }
        if (status === 'loading') {
            setInitialized(false)
        }
    }, [status])

    useEffect(() => {
        if (zoomSeat) {
            moveTo(Number(zoomSeat))
        }
    }, [zoomSeat])

    // useEffect(() => {
    //     if (stageRef.current) {
    //         setStage(stageRef.current)
    //     }
    // }, [stageRef])

    const resize = async () => {
        if (!src) return
        const scaleX = width / src.width
        const scaleY = (height - 60) / src.height
        const newScale = Math.min(scaleX, scaleY)

        onRescale({
            scale: newScale,
            point: {
                x: -(src.width * newScale - width) / 2,
                y: -(src.height * newScale - height + 60) / 2
            }
        })
    }

    const moveTo = (point) => {
        if (!src || !point) return

        const stage = stageRef.current
        const el = stage.findOne('#point' + point)

        if (!el) return

        const pos = el.getAbsolutePosition(stage)
        const pointSize = src.width * pointOptions.labelSize
        const maxCount = src.width / pointSize
        const stageScale = stage.width() / (pointSize * (maxCount / 2))

        const finalScale = Math.max(scale, stageScale)

        onRescale({
            scale: finalScale,
            point: {
                x: -pos.x * finalScale + width / 2,
                y: -pos.y * finalScale + height / 2,
            }
        })
    }


    if (status == 'loading') return <div>Загрузка карты...</div>

    if (!src || status === 'failed') return <div>Произошла ошибка при загрузке карты...</div>

    return (
        <Stage
            ref={stageRef}
            width={width}
            height={height - 60}
            onWheel={handleScale}
            draggable
        >
            <Layer>
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config={{ duration: 300 }}
                >
                    {(props) => (
                        // @ts-ignore
                        <animated.Group {...props}>
                            <Image name="mapLayer" image={src} />
                            <Group>
                                {polygons.map(({ id, polygon }) => (
                                    <Polygon
                                        key={id}
                                        width={src?.width}
                                        height={src?.height}
                                        id={id}
                                        polygon={polygon}
                                    />
                                ))}
                            </Group>

                            <Group>
                                {points.map((point) => (
                                    <AnimatedPoint
                                        key={point.id}
                                        point={point}
                                        width={src.width}
                                        height={src.height}
                                        pointOptions={pointOptions}
                                        metadata={metadata}
                                        bookings={data?.bookings}
                                    />
                                ))}
                            </Group>
                        </animated.Group>
                    )}
                </Spring>
            </Layer>
        </Stage>
    )
}

export default React.memo(Map)

Map.whyDidYouRender = true

export const getImageLink = (image) => {
    if (!image.owner_id) return ''
    return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}`
}

export const defaultMapPointImage = {
    owner_id: "15",
    image_id: "2d95a0df350c4c2dae7ab2c0c03bf337",
    extension: "png"
}