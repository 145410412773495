import AppLayout from '../components/layout/AppLayout'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useProjectStore } from '@/stores/projectStore'
import MapContainer from './MapContainer';
import { useGlobalStore } from '@/stores/globalStore';
import LayerInfoModal from '@/components/layout/Sidebar/Layers/LayerInfoModal'
import { useLocation } from 'react-router-dom';
import { useTree } from '@/api/hooks/useTree';
import qs from 'query-string';
import { useMapStore } from '@/stores/mapStore'
import Map from '@/components/shared/map/Map';

const ProjectContainer: React.FC = () => {
    return (
        <AppLayout>
            {/* <p>Welcome project page</p> */}
            <Map />
            {/* <MapContainer /> */}
            <LayerInfoModal />
            <SideEffector />
        </AppLayout>
    )
}

export default ProjectContainer

ProjectContainer.whyDidYouRender = true

const SideEffector = () => {
    const navigate = useNavigate()
    const location = useLocation()

    // data selectors
    const activeLayer = useGlobalStore(state => state.activeLayer)
    const employee = useGlobalStore(state => state.employee)
    const seat = useGlobalStore(state => state.seat)
    const setNodes = useProjectStore(state => state.setNodes)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setEmployee = useGlobalStore(state => state.setEmployee)
    const setSeat = useGlobalStore(state => state.setSeat)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)

    const { layers } = useTree()
    const queryParams = qs.parse(location.search)
    const mapID = queryParams.map
    const employeeID = queryParams.employee
    const pointID = queryParams.point

    useEffect(() => {
        if (layers) {
            if (layers.layer && !mapID) {
                setActiveLayer(Number(layers.layer.id))
            }
            setNodes(layers?.nodes)
        }
    }, [layers])

    useEffect(() => {
        if (mapID) {
            setActiveLayer(Number(mapID))
        }
        if (pointID) {
            setZoomSeat(Number(pointID))
        }
    }, [])

    useEffect(() => {
        if (employeeID) {
            setEmployee(Number(employeeID))
        }
    }, [employeeID])

    useEffect(() => {
        if (pointID) {
            setSeat(Number(pointID))
        }
    }, [pointID])

    useEffect(() => {
        const newQueries = { ...queryParams, employee, point: null }

        navigate({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [employee])

    useEffect(() => {
        const newQueries = { ...queryParams, map: activeLayer, point: seat, employee: null }

        navigate({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [seat, activeLayer])

    return null
}

// SideEffector.whyDidYouRender = true