import React from 'react'
import styled from 'styled-components';
import { FormControls } from '@/components/ui/form/FormControls';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';

const MobileBookingItem = ({ fio, name, type, date, handleEdit, handleDelete }) => {
    return (
        <ItemWrapper>
             {fio && <Name>{fio}</Name>}
            <Name>{name}</Name>
            <PropsWrapper>
                <Property>
                    <PropName>Тип</PropName>
                    <PropValue>{type}</PropValue>
                </Property>
                <Property>
                    <PropName>Дата</PropName>
                    <PropValue>{date}</PropValue>
                </Property>
            </PropsWrapper>
            <FormControls>
                <PrimaryButton onClick={handleEdit}>Редактировать</PrimaryButton>
                <DefaultButton onClick={handleDelete}>Удалить</DefaultButton>
            </FormControls>
        </ItemWrapper>
    )
}

export default MobileBookingItem

const ItemWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px 10px;
    color: #000;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const PropsWrapper = styled.div`
    margin: 6px 0 12px 0;
`

const PropName = styled.div`
    max-width: 50px;
    width: 100%;
`
const PropValue = styled.div`
    width: 100%;
    font-weight: 700;
`

const Property = styled.div`
    display: flex;
    
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Name = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`