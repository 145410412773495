import { useParallelBooking } from '@/api/hooks/useParallelBooking'
import React from 'react'
import { useFormikContext } from 'formik';
import { useBookingStore } from '@/stores/bookingStore';
import { endOfWeek, startOfWeek } from 'date-fns';
import styled from 'styled-components';
import { format } from 'date-fns';
import Grid from '@/components/Grid';
import { ErrorBoundary } from 'react-error-boundary';
import { BookingDate, bookingTypeTranslate } from '@/components/layout/Sidebar/Bookings';
import FormLoader from '@/components/ui/form/FormLoader';
import useResponsive from '@/hooks/useResponsive';
import MobileBookingItem from '@/components/layout/Sidebar/MobileBookingItem';

const ParallelBookings = () => {
    const week = useBookingStore(state => state.week)

    const weekStart = startOfWeek(week, { weekStartsOn: 1 })
    const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

    const { values } = useFormikContext<any>()
    const { data, isLoading } = useParallelBooking({ nodeId: values.seat.id, userId: values.user.id, weekStart, weekEnd })

    const filtered = data?.node_booking_view_v2.items.filter(book => (book.point_id == values.seat.id) && book.user !== values.user.id) || []

    return (
        <ParallelsWrapper>
            <Period>Брони на период: {format(weekStart, 'dd.MM.yyyy')} - {format(weekEnd, 'dd.MM.yyyy')}</Period>
            <BookingsWrapper>
                {filtered.length ? (
                    <div>
                        {filtered.map(item => (
                            <ParrallelBook key={item.id} item={item} />
                        ))}
                    </div>
                ) : (
                    <div>Параллельные брони отсутствуют</div>
                )}
            </BookingsWrapper>
            {/* <FormLoader isLoading={isLoading} /> */}
        </ParallelsWrapper>
    )
}

export default ParallelBookings

const ParrallelBook = ({ item }) => {
    const bookingType = item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
    const { isDesktop } = useResponsive()

    if (!isDesktop) return (
        <ItemWrapper>
            <Name>{item.user_name}</Name>
            <PropsWrapper>
                <Property>
                    <PropName>Тип</PropName>
                    <PropValue>{bookingTypeTranslate[bookingType]}</PropValue>
                </Property>
                <Property>
                    <PropName>Дата</PropName>
                    <PropValue>
                        <ErrorBoundary
                            fallback={<div>Ошибка отображения даты</div>}
                        >
                            <BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />
                        </ErrorBoundary>
                    </PropValue>
                </Property>
            </PropsWrapper>
        </ItemWrapper>
    )

    return (
        <Grid.Line $cols="auto auto 1fr">
            <Grid.Item>{item.user_name}</Grid.Item>
            <Grid.Item>{bookingTypeTranslate[bookingType]}</Grid.Item>
            <Grid.Item>
                <ErrorBoundary
                    fallback={<div>Ошибка отображения даты</div>}
                >
                    <BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />
                </ErrorBoundary>
            </Grid.Item>
        </Grid.Line>
    )
}

const ParallelsWrapper = styled.div`
    padding: 20px 0;
`

const Period = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
`

const BookingsWrapper = styled.div`
    margin-top: 20px;
`




const ItemWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 15px 10px;
    color: #000;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`

const PropsWrapper = styled.div`
    margin: 6px 0 12px 0;
`

const PropName = styled.div`
    max-width: 50px;
    width: 100%;
`
const PropValue = styled.div`
    width: 100%;
    font-weight: 700;
`

const Property = styled.div`
    display: flex;
    
    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Name = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`