import create from 'zustand'

type MapState = {
    initialized: boolean
    isLoading: boolean
    size: [number, number]
    stage: any
    initialScale: number
    zoomSeat: number | null
    polygon: number | null
    setInitialized: (initialized: boolean) => void
    setIsLoading: (isLoading: boolean) => void
    setSize: (size: [number, number]) => void
    setInitialScale: (initialScale: number) => void
    setStage: (stage) => void
    setZoomSeat: (seat: number | null) => void
    setPolygon: (polygon: number | null) => void
}

export const useMapStore = create<MapState>(set => ({
    initialized: false,
    isLoading: false,
    size: [0, 0],
    stage: null,
    initialScale: 1,
    zoomSeat: null,
    polygon: null,
    setInitialized: (initialized) => set(() => ({ initialized })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    setSize: (size) => set(() => ({ size })),
    setInitialScale: (initialScale) => set(() => ({ initialScale })),
    setStage: (stage) => set(() => ({ stage, initialized: true })),
    setZoomSeat: (zoomSeat) => set(() => ({ zoomSeat })),
    setPolygon: (polygon) => set(() => ({ polygon })),
}))

type MapPointType = {
    x: number
    y: number
}

interface OnRescaleProps {
    scale: number
    point: MapPointType
}