import { useGlobalStore } from '@/stores/globalStore';

// export function useProject() {
//     const params = useParams<{ workspaceId: string, projectId: string }>()
    
//     const [paramsState, setParamsState] = useState(params)

//     useEffect(() => {
//         if (!isEqual(paramsState, params)) {
//             setParamsState(params)
//         }
//     }, [params])

//     return useMemo(() => ({
//         workspaceId: Number(paramsState.workspaceId),
//         projectId: Number(paramsState.projectId)
//     }), [paramsState])
// }

export function useProject() {
    const project = useGlobalStore(state => state.project)

    return project
}
