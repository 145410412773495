import React from 'react'
import Grid from '@/ui/components/Grid';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import { Field } from 'formik';
import { DatePickerField } from '@/ui/components/Field/DatePickerField';
import { useFormikContext } from 'formik';
import { addMinutes, getHours, getMinutes, isAfter, isSameDay, setHours, setMinutes } from 'date-fns';
import { isEqual } from 'lodash';

// {values.type != '1' && (

//     )}

const GlobalIntervals = () => {

    const { values, setFieldValue } = useFormikContext<any>()

    const type = values.type != '1'
    const interval = {
        start: values.start || '',
        end: values.end || ''
    }

    const start = interval.start ? addMinutes(interval.start, 30) : null

    const isItSame = interval.end ? isSameDay(interval.start, interval.end) : true
    const minHours = start ? getHours(start) : 0
    const minMinutes = start ? getMinutes(start) : 0
    const minTime = isItSame ? setHours(setMinutes(new Date(), minMinutes), minHours) : setHours(setMinutes(new Date(), 0), 0)


    if (!type) return null

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <FormLabel>Дата и время начала</FormLabel>
                    <Field
                        name="start"
                        $fullWidth
                        placeholder="Время начала"
                        component={DatePickerField}
                        onChange={val => {
                            const isIntervalAfter = interval.end && val ? isAfter(val, interval.end) : false
                            const equality = interval.end && val ? isEqual(val, interval.end) : false
    
                            if (isIntervalAfter || equality) {
                                setFieldValue(`end`, addMinutes(val, 30))
                            }
    
                            setFieldValue(`start`, val)
                        }}
                    />
                </Grid>
                {values.type === '2' && (
                    <Grid item xs={12} md={4}>
                        <FormLabel>Дата и время окончания</FormLabel>
                        <Field
                            name="end"
                            $fullWidth
                            type="text"
                            placeholder="Время окончания"
                            openToDate={start}
                            minDate={start || null}
                            minTime={minTime}
                            maxTime={setHours(setMinutes(new Date(), 59), 23)}
                            component={DatePickerField}
                            onChange={val => {
                                const isIntervalAfter = interval.start && val ? isAfter(val, interval.start) : false
                                const equality = interval.start && val ? isEqual(val, interval.start) : false
        
                                if (equality || !isIntervalAfter) {
                                    setFieldValue(`end`, addMinutes(interval.start, 30));
                                } else {
                                    setFieldValue(`end`, val);
        
                                }
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

export default GlobalIntervals