import React from 'react'
import styled from 'styled-components';

const Datatable = ({ columns, data }) => {

    return (
        <Wrapper>
            <table cellPadding={0} cellSpacing={0} role="table">
                <thead>
                    <tr>
                        {columns.map(col => <th key={col.accessor}>{col.label}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map(row => (
                            <tr>
                                {columns.map(column => <td>{row[column.accessor]}</td>)}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </Wrapper>
    )
}

export default Datatable

const Wrapper = styled.div`
    width: 100%;

    table {
        width: 100%;
    }

    thead {
        th {
            text-align: left;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
    }
`