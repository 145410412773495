import { useQuery } from 'react-query';
import { useProject } from '@/hooks/useProject';
import { LayerService } from '../services/layer.service';
import { useToast } from '@/components/shared/toast/useToast';

export const useLayerView = (layer?: number | null) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['layer_view_', layer],
        () => LayerService.getLayerView({
            workspaceId,
            projectId,
            layerId: Number(layer)
        }),
        {
            enabled: !!workspaceId && !!projectId && !!layer,
            select: ({ data }) => ({
                view: data.layer_view,
                image: LayerService.getLayerImage({
                    layerId: Number(layer),
                    imageName: data.layer_view.image.image_id,
                    extension: data.layer_view.image.extension
                }),
                points: data.layer_view.points,
                polygons: data.layer_view.polygons,
                options: {
                    labelSize: data.layer_view.map_node.label_size / 100,
                    fontSize: data.layer_view.map_node.font_size,
                    color:  data.layer_view.map_node.color,
                    borderWidth: data.layer_view.map_node.borderwidth,
                    wrapText: data.layer_view.map_node.wrap_text
                }
            }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить данные о слое' }, { variant: 'error' })
            }
        }
    )

    return { layerView: data, isLoading, refetch }
}