import { useNodes } from '@/api/hooks/useNodes';
import Close from '@/components/Close';
import { Place } from '@/components/Elements/ElementsListItem';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import Pagination from '@/ui/components/Pagination/Pagination';
import media from '@/ui/media';
import React, { useState } from 'react';
import styled from 'styled-components'

type ReportModalProps = {
    report: string | null
    item: {
        name: string
        key: string
    }
    onClose: () => void
}

const ReportModal: React.FC<ReportModalProps> = ({ report, item, onClose }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { data, isLoading } = useNodes({ page: currentPage - 1 })

    return (
        <Modal isOpen={!!report} onClose={onClose}>
            <Header>
                <Title>Отчет "{item.name}"</Title>
                <ExportWrapper>
                    <ReportButton>PDF</ReportButton>
                    <ReportButton>CSV</ReportButton>
                    <ReportButton>Печать</ReportButton>
                    <Close color="#000" onClick={onClose} />
                </ExportWrapper>
            </Header>

            <Grid>
                <Grid.RowHeader $cols="2fr 1fr 100px">
                    <Grid.Item>Название места</Grid.Item>
                    <Grid.Item>Расположение</Grid.Item>
                    <Grid.Item>% занятости</Grid.Item>
                </Grid.RowHeader>

                {data?.items.length ? data?.items.map(spot => <ReportItem key={spot.id} item={spot} />) : (
                    <NotFound>Ничего не найдено</NotFound>
                )}

            </Grid>

            <Pagination inverse currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} />
        </Modal>
    )
}

export default ReportModal

const ReportItem = ({ item }) => {
    const getRandomPercent = randomIntFromInterval(0, 25)
    return (
        <Grid.Row $cols="2fr 1fr 100px">
            <Grid.Item>{item.name}</Grid.Item>
            <Grid.Item>
                <Place item={item} />
            </Grid.Item>
            <Grid.Item>{getRandomPercent}</Grid.Item>
        </Grid.Row>
    )
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

const NotFound = styled.div`
    width: 100%;
    text-align: center;
    padding: 12px 0;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const ExportWrapper = styled.div`
    display: flex;
    align-items: center;

    * {
        &:not(:last-child) {
            margin-right: 0.8rem;
        }
    }

    
    ${media.lg`
        margin-top: 20px;
    `}
`

const ReportButton = styled.button`
    border: 2px solid #044D8C;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #044D8C;
    padding: 1.2rem 2rem;
    outline: none;
    background: transparent;
    cursor: pointer;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`